$(document).ready(function() {
    handleNavbar();
    matchHeight();
    handleCarousel();
    gallery();
    handleTable();
    handleStickyNavbar();
    floatingFormLabels();
    customFileUpload();
    youtubeVideoComponent();
    bgImageSlider();
    customSelect();
    handleSelect();

    $('body').addClass("loading");

    $(window).resize(function() {

    });
});

$(window).on("load", function() {

    handleFooter();
    handleNavCategory();
    $(window).trigger('resize');

});

function handleNavbar() {
    //add dropdown icon
    $("#nav-categories li > a").each(function(index, item) {

        if ($(item).next('ul').length) {
            $(this).parent().addClass('has-subitems');
        }

    });
    $("body.is_tablet #nav-main li.has-subitems > a, body.is_tablet #nav-categories li.has-subitems > a").click(function(e) {

        if ($(this).parent().hasClass("show")) {
            $(this).parent().removeClass("show");
            location.href = e.attr('href', url);
        } else {
            $("body.is_tablet #nav-main li.has-subitems, body.is_tablet #nav-categories li.has-subitems").removeClass("show");
            $(this).parent().addClass("show");
        }
        e.preventDefault();
    });
}

function matchHeight() {
    $('article .block-title').matchHeight();
}

function handleCarousel() {
    $('.owl-logo-carousel').owlCarousel({
        items: 1,
        // margin: 50,
        responsive: {
            // breakpoint from 0 up
            0: {
                items: 1,
                // margin: 50,
            },
            // breakpoint from 480 up
            480: {
                items: 2,
                // margin: 50,
            },
            // breakpoint from 768 up
            768: {
                items: 3,
                // margin: 50,
            },
            1024: {
                items: 4,
                margin: 100
            },
            1600: {
                items: 4,
                margin: 150
            }
        }
    });

    $('.owl-usp-carousel').owlCarousel({
        items: 1,
        loop: true,
        autoplayTimeout: 6000,
        autoplay: true,
        dots: false,
        responsive: {
            // breakpoint from 768 up
            768: {
                items: 2
            },
            1024: {
                items: 3,
                loop: false
            },
            1200: {
                items: 3,
                loop: false,
                autoWidth: true
            },
        }
    });
}

function gallery() {

    $(".gallery").each(function(index, item) {

        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile mfp-fade',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function(item) {
                    return item.el.attr('title');
                }
            },
            removalDelay: 300
        });

        var galleryLength = $(item).find("a").length;

        if (galleryLength == 1) {
            $(item).addClass('one-image');
        } else if (galleryLength == 2) {
            $(item).addClass('two-image');
        } else if (galleryLength == 3) {
            $(item).addClass('three-image');
        } else if (galleryLength >= 4) {
            $(item).addClass('more');
        }
    });
}

window.onload = function() {
    progressiveImageLoader();
    progressiveBackgroundImage();
};

function progressiveBackgroundImage() {

    var placeholder = $(".bg-progressive");

    placeholder.each(function(index, element) {

        var imgUrlSmall = $(element).data('small');
        var imgUrlLarge = $(element).data('large');

        var imageWrapperSmall = $('<div class="background-image-small"></div>');
        var imageWrapperLarge = $('<div class="background-image-large"></div>');

        $(imageWrapperSmall).css("background-image", "url(" + imgUrlSmall + ")");
        $(imageWrapperLarge).css("background-image", "url(" + imgUrlLarge + ")");

        $(imageWrapperSmall).appendTo(element);
        $(imageWrapperLarge).appendTo(element);

        var img = new Image();
        img.src = imgUrlLarge;

        img.onload = function() {
            $(imageWrapperSmall).addClass('loaded');
            $(imageWrapperLarge).addClass('loaded');
        };
    });
}

function progressiveImageLoader() {
    var placeholder = $("figure.figure-progressive");

    placeholder.each(function(index, element) {

        //load small image
        var imageWrapperSmall = $('<img class="image-small">');
        imageWrapperSmall.attr('src', $(element).attr('data-small'));

        // //load large image
        var imageWrapperLarge = $('<img class="image-large">');
        imageWrapperLarge.attr('src', $(element).attr('data-large')).attr('srcset', $(element).attr('data-srcset')).attr('alt', $(element).attr('data-alt'));;

        imageWrapperSmall.appendTo(element);
        imageWrapperLarge.appendTo(element);

        var img = new Image();
        img.src = $(element).attr('data-large');

        //create image in DOM
        img.onload = function() {
            $(imageWrapperSmall).addClass('loaded');
            $(imageWrapperLarge).addClass('loaded');
        };

    });
}

function handleFooter() {
    $(window).resize(function() {
        var contentHeight = Math.round($("body").innerHeight());
        var windowWidth = $(window).innerWidth();
        var windowHeight = window.innerHeight;

        if ((windowWidth >= 768) && (contentHeight <= windowHeight)) {
            $("footer").addClass("sticky");
        } else {
            $("footer").removeClass("sticky");
        }
    });
}

function handleTable() {
    $('table').addClass('table table-striped');
}

function handleStickyNavbar() {
    var navbar = $("#navbar");

    navbar.removeClass("sticky");

    $(window).scroll(function() {

        if (navbar !== undefined && navbar !== null) {
            var offsetTopPosition = navbar.offsetTop;

            if (window.pageYOffset >= 1) {
                navbar.addClass("sticky")
            } else {
                navbar.removeClass("sticky");
            }
        }
    });

}

function floatingFormLabels() {
    $('.floating-label').each(function() {
        var inputField = $(this).find('input, textarea');

        if (inputField.val()) {
            $(this).addClass('float');
        } else {
            $(this).removeClass('float');
        }

        $('input, textarea').on('change paste keyup focus', function(index, element) {
            if ($(this).val() != "") {
                $(this).parent().addClass('float');
            } else {
                $(this).parent().removeClass('float');
            }
        });

    });
}

function customFileUpload() {
    $('input[type=file]').each(function(index, element) {
        var fileElement = $(element).parent();
        var fileNameDiv = '<div class="custom-upload-filename"></div>';
        $(fileNameDiv).appendTo(fileElement);

        $(element).on('change', function() {
            $(fileElement).find(".custom-upload-filename").empty();
            var filename = $(element).val().replace(/.*(\/|\\)/, '');
            fileElement.find(".custom-upload-filename").append(filename);
        });
    });
}

function youtubeVideoComponent() {
    var tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


    $(".video-wrapper").each(function(index, element) {
        var videoWrapper = $(element);
        $(element).on('click', function(index, element) {
            var youtubeHash = $("#player").attr("data-youtube-hash");

            $(videoWrapper).toggleClass("pullout");
            $("body").toggleClass("fixed");

            if ($('.video-wrapper').hasClass('pullout')) {
                $('.video-close').removeClass('invisible');
                $('.video-close').addClass('visible');
            }

            if (!$('.video-wrapper').hasClass('pullout')) {
                $('.video-close').removeClass('visible');
                $('.video-close').addClass('invisible');
            }

            if ($("#player").is("iframe")) {
                $("#player").replaceWith("<div id='player' data-youtube-hash='" + youtubeHash + "'></div>");
            } else {
                var player = new YT.Player('player', {
                    height: '390',
                    width: '640',
                    videoId: youtubeHash,
                    events: {
                        'onReady': onPlayerReady,
                        'onPlaying': onPlaying,
                        'onclick': onPlayerClick
                    }
                });
            }

        });

        function onPlayerClick(event) {
            event.target.stopVideo();
        }

        function onPlayerReady(event) {
            event.target.playVideo();
        }

        function onPlaying(event) {
            event.target.stopVideo();
        }
    });
}

function bgImageSlider() {
    var imageSlider = $(".bg-image-slider div");
    var i = 0;
    var timeout = 10000;

    function changeActiveState() {
        if (i >= imageSlider.length) {
            i = 0;
        }

        $(".bg-progressive").removeClass("active");
        $(imageSlider[i]).addClass("active");

        i++;
    }

    setInterval(changeActiveState, timeout);
}

function handleNavCategory() {
    var parentElement = $("#nav-categories");
    var parentElementWidth = parentElement.width();


    $(parentElement).find("> li").each(function(index, item) {
        var childElementPositionLeft = this.offsetLeft;
        var childElementSubitemsWidth = $(item).find("ul").innerWidth();
        // console.log(childElementSubitemsWidth);
        if ((childElementPositionLeft + childElementSubitemsWidth) > parentElementWidth) {
            $(item).attr("data-position", "left");
        }
    });
}

function customSelect() {
    var $selectElem = $("select.custom-select");
    var optionItems = new Array();
    var optionValues = new Array();
    var list = [];

    $('select.custom-select option').each(function() {
        optionItems.push(this.text);
        optionValues.push(this.value);
    });

    _.forEach(optionItems, function(value, index) {
        var value_clear = $(value).text();

        if (value_clear == '') {
            list.push("<li role='option' class='1' aria-selected='false' data-option='" + optionValues[index] + "'>" + value + "</li>");
        } else {
            list.push("<li role='option' class='2' aria-selected='false' data-option='" + optionValues[index] + "'>" + value_clear + "</li>");
        }
    });

    var customSelect = '<div class="md-select" id="' + $selectElem.attr('id') + '">\n' +
        '          <label for="' + $selectElem.attr('id') + '">\n' +
        '            <button type="button">' + $selectElem.find('option:first-of-type').text() + '</button>\n' +
        '          </label>\n' +
        '          <ul role="listbox" id="' + $selectElem.attr('id') + '" name="' + $selectElem.attr('name') + '">' + list.join("") + '</ul>\n' +
        '        </div>';

    $(customSelect).insertBefore($selectElem);
}

function handleSelect() {
    $('.md-select').each(function(index, element) {
        $(element).click('click', function(e) {
            e.preventDefault();
            $(element).toggleClass('active')

        });
        $(element).find('ul li').on('click', function() {
            //default list
            if ($(this).find('input').length === 0) {
                $(element).find('ul li').attr('aria-selected', false);
                $(this).attr('aria-selected', true);

                //multiselect list
            } else {
                if ($(this).attr('aria-selected') === 'false') {
                    $(this).attr('aria-selected', true);
                } else {
                    $(this).attr('aria-selected', false);
                }
            }

            var value = $(this).attr("data-option");
            var text = $(this).text();

            $(element).find('ul li').not($(this)).removeClass('active');
            $(this).removeClass('active');
            $(element).find('label button').text(text);

            $(element).next().val(value);
            $(element).next().trigger('change');
        })
    });
}